import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Card, CardContent, CardActions, Button, Link, Paper, Alert, AlertTitle, Divider, Box, IconButton,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  ToggleButtonGroup, ToggleButton, CardMedia, Grid, Typography, ButtonGroup, Stack, Chip } from '@mui/material'
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import LockIcon from '@mui/icons-material/Lock'

import { SIDEBET_COMMISSION, CREATOR_COMMISSION } from 'app/shared/constants'
import { Loading, Snapshot } from 'app/components'
import { useGetWagerQuery, useCreateWagerEntryMutation } from 'app/reducers/api'
import { formatCurrency } from 'app/shared/utils'

const ContestsShow = ({ contest_id: wager_id }) => {
  const navigate = useNavigate()
  const [wagerAmount, setWagerAmount] = useState(2500)
  const [customWagerAmount, setCustomWagerAmount] = useState(0)
  const [selectedTeam, setSelectedTeam] = useState(null)
  const [submittingWagerEntry, setSubmittingWagerEntry] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [createWagerEntry, {}] = useCreateWagerEntryMutation()

  const {
    data: wager,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
    isFetching
  } = useGetWagerQuery(wager_id)

  useEffect(() => {
    refetch()
  }, [])


  const handleCreateContestEntry = async (e) => {
    e.preventDefault()
    setSubmittingWagerEntry(true)
    let contestEntry =  {
      amount: currentWagerAmount(),
      selected_team_id: selectedTeam,
      wager_id: wager.id
    }

    try {
      let response = await createWagerEntry(contestEntry).unwrap()
      setDialogOpen(false)
      setSubmittingWagerEntry(false)
      navigate(`/contest_entries/${response.id}`)
    } catch(err) {
      console.error('could not place wager entry', err)
      setDialogOpen(false)
      setSubmittingWagerEntry(false)
    }
  }

  const currentWagerAmount = () => {
    return wagerAmount == -1 ? customWagerAmount * 100 : wagerAmount
  }

  const wagerAmountDisplay = () => {
    return formatCurrency(currentWagerAmount())
  }

  const currentWinningsEstimate = () => {
    let estimatedWinnings = 0
    if(wager){
      let wagerAmount = currentWagerAmount()
      let currentTeamAmount = (wager.contest_stats && wager.contest_stats['teams'] && wager.contest_stats['teams'][selectedTeam]) ? wager.contest_stats['teams'][selectedTeam]['total'] : 0
      let newTeamAmount = currentTeamAmount + wagerAmount
      let currentGross = (wager.contest_stats && wager.contest_stats['total']) ? wager.contest_stats['total'] : 0
      let newNet = (currentGross + wagerAmount) * (1 - SIDEBET_COMMISSION + CREATOR_COMMISSION)
      let newTeamOdds = newNet / newTeamAmount
      estimatedWinnings = wagerAmount * newTeamOdds
    }
    return formatCurrency(estimatedWinnings)
  }

  const renderConfirmationDialog = () => {
    return (
      <>
      { wager && wager.teams && (
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          { submittingWagerEntry ? <Loading message="One sec while we place your contest entry..." /> : (
            <>
            <DialogTitle id="alert-dialog-title">
              {"Confirm your Sidebet!"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Stack spacing={2} alignItems="center">
                  <Typography variant="subtitle2">{ `Your Pick: Team ${Object.keys(wager.teams).indexOf(selectedTeam) + 1}` }</Typography>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{ `${wagerAmountDisplay()} Entry for ${currentWinningsEstimate()} Prize*` }</Typography>
                  <Typography align="center">Please review your contest entry above and click Confirm to submit.</Typography>
                </Stack>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDialogOpen(false)}>cancel</Button>
              <Button variant="contained" color="success" onClick={handleCreateContestEntry} autoFocus>Confirm</Button>
            </DialogActions>
            </>
          )}
        </Dialog>
      )}
      </>
    )
  }

  const renderWagerTeams = () => {
    return (
      <Stack spacing={2} direction="row">
        { wager && wager.teams && wager.contest_stats && Object.keys(wager.teams).map((teamId, i) => {
          let team_stats = wager.contest_stats && wager.contest_stats["teams"] && wager.contest_stats["teams"][teamId]
          return (
            <Card spacing={1} key={teamId} onClick={() => setSelectedTeam(teamId)} style={{ border: `${selectedTeam == teamId ? '1px solid green' : 'none' }` }} disableElevation>
              <CardContent>
                <Grid container>
                  <Grid item xs={6}>
                  { selectedTeam && selectedTeam == teamId ? <CheckCircleIcon color="success" /> : <RadioButtonUncheckedIcon /> }
                  </Grid>
                  <Grid item xs={6}>
                    { team_stats && (
                      <>
                      <Typography variant="subtitle2">{ `Total: ${ formatCurrency(team_stats["total"] || 0) }` }</Typography>
                      <Typography variant="subtitle2">{ `Odds: ${ team_stats["odds_display"] || 'N/A' }` }</Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Stack spacing={2} display="flex" alignItems="center" justifyContent="center">
                  <Typography variant="h6">{ `Team ${i+1}` }</Typography>
                  { Object.keys(wager.teams[teamId]).map((playerId, j) => {
                    return (
                      <Card key={`${teamId}_${playerId}`}>
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
                              <CardMedia
                                component="img"
                                image={wager.teams[teamId][playerId]["team"]["logo"]}
                                width="25%"
                              />
                            </Grid>
                            <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
                              <Stack>
                                <Typography align='center'>{ `${wager.teams[teamId][playerId]["player"]["position"]} | ${wager.teams[teamId][playerId]["player"]["name"]}` }</Typography>
                                <Typography variant='subtitle2' align='center' style={{ fontSize: '0.7rem' }}>{ wager.teams[teamId][playerId]["game"]["date_display_short"] }</Typography>
                              </Stack>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    )
                  })}
                </Stack>
              </CardContent>
              <CardActions>
              </CardActions>
            </Card>
          )
        })}
      </Stack>
    )
  }

  const renderWagerTeamsResult = () => {
    return (
      <Stack spacing={2} direction="row">
        { wager && wager.teams && wager.contest_results && Object.keys(wager.teams).map((teamId, i) => {
          let winningTeam = wager.contest_results && wager.contest_results["winning_team"]
          let team_stats = wager.contest_stats && wager.contest_stats["teams"] && wager.contest_stats["teams"][teamId]
          let team_result_stats = wager.contest_results && wager.contest_results["teams"] && wager.contest_results["teams"][teamId]
          return (
            <Card spacing={1} key={teamId} style={{ border: `${winningTeam == teamId ? '1px solid green' : 'none' }` }} disableElevation>
              <CardContent>
                <Grid container>
                  <Grid item xs={6}>
                  { winningTeam && winningTeam == teamId ? <CheckCircleIcon color="success" /> : <RadioButtonUncheckedIcon /> }
                  </Grid>
                  <Grid item xs={6}>
                    { team_stats && team_result_stats && (
                      <>
                      <Typography variant="subtitle2">{ `Total: ${ formatCurrency(team_stats["total"] || 0) }` }</Typography>
                      <Typography variant="subtitle2">{ `Odds: ${ team_stats["odds_display"] || 'N/A' }` }</Typography>
                      <Typography variant="subtitle2">{ `Score: ${ team_result_stats["score"] || 'N/A' }` }</Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Stack spacing={2} display="flex" alignItems="center" justifyContent="center">
                  <Typography variant="h6">{ `Team ${i+1}` }</Typography>
                  { Object.keys(wager.teams[teamId]).map((playerId, j) => {
                    return (
                      <Card key={`${teamId}_${playerId}`}>
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
                              <CardMedia
                                component="img"
                                image={wager.teams[teamId][playerId]["team"]["logo"]}
                                width="25%"
                              />
                            </Grid>
                            <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
                              <Stack>
                                <Typography align='center'>{ `${wager.teams[teamId][playerId]["player"]["position"]} | ${wager.teams[teamId][playerId]["player"]["name"]}` }</Typography>
                                <Typography variant='subtitle2' align='center' style={{ fontSize: '0.7rem' }}>{ `Score: ${team_result_stats["players"][playerId]["score"]}` }</Typography>
                              </Stack>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    )
                  })}
                </Stack>
              </CardContent>
              <CardActions>
              </CardActions>
            </Card>
          )
        })}
      </Stack>
    )
  }

  const renderWagerEntryForm = () => {
    return (
      <Stack spacing={1}>
        { selectedTeam && (
          <>
          <Typography variant="caption">{ `Your Pick: Team ${Object.keys(wager.teams).indexOf(selectedTeam) + 1}` }</Typography>
          <ToggleButtonGroup
            value={wagerAmount}
            exclusive
            onChange={(_, selectedWagerAmount) => setWagerAmount(selectedWagerAmount) }
            aria-label="contest type selector"
            fullWidth>
            <ToggleButton value={500} aria-label="five" sx={{ borderRadius: '25px 0 0 25px' }}>$5</ToggleButton>
            <ToggleButton value={1500} aria-label="fifteen" sx={{ borderRadius: '0 25px 25px 0' }}>$15</ToggleButton>
            <ToggleButton value={2500} aria-label="twentyfive" sx={{ borderRadius: '0 25px 25px 0' }}>$25</ToggleButton>
            <ToggleButton value={-1} aria-label="custom" sx={{ borderRadius: '0 25px 25px 0' }}>Custom</ToggleButton>
          </ToggleButtonGroup>
          { wagerAmount == -1 && (
            <CurrencyTextField
              label="Custom Entry Fee"
              variant="standard"
              value={customWagerAmount}
              currencySymbol="$"
              minimumValue="0"
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(_, value)=> setCustomWagerAmount(value)}
              />
          )}
          </>
        )}
        <Button variant="contained" color="success" onClick={() => setDialogOpen(true)} disabled={!selectedTeam}>
          Place Entry
        </Button>
      </Stack>
    )
  }

  const renderWagerLocked = () => {
    return (
      <Stack spacing={1} direction="row" display="flex" justifyContent="center" alignItems="center">
        <LockIcon size="large" />
        <Typography variant="caption">{ `Contest Ends: ${wager.end_time}` }</Typography>
      </Stack>
    )
  }

  const renderWagerResult = () => {
    return (
      <Stack spacing={1} direction="row" display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h5">{ `Contest Final: Team ${Object.keys(wager.teams).indexOf(wager.contest_results.winning_team) + 1} Won!` }</Typography>
      </Stack>
    )
  }


  const renderWagerDetails = () => {
    return (
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="subtitle2">{ wager.name }</Typography>
          <Typography variant="caption">{ `by ${wager.creator}` }</Typography>
          <Typography variant="subtitle2">{ `Pool Size: ${formatCurrency(wager.contest_stats ? wager.contest_stats['total'] || 0 : 0)}` }</Typography>
        </Grid>
        <Grid item xs={6}>
          { selectedTeam && wager.status == 'pending' && renderWagerEntryForm() }
          { wager.status == 'locked' && renderWagerLocked() }
          { wager.status == 'finalized' && renderWagerResult() }
        </Grid>
      </Grid>
    )
  }

  const renderWager = () => {
    return (
      <Stack spacing={2}>
        { renderWagerDetails() }
        { wager.status == 'finalized' ? renderWagerTeamsResult() : renderWagerTeams() }
      </Stack>
    )
  }

  return (
    <>
      { error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
        </Alert>
      )}
      { (isFetching || isLoading) ? <Loading message="One sec while we load your contest..." /> : renderWager() }
      { renderConfirmationDialog() }
    </>
  )
};

export default ContestsShow;
